class IsCounter extends HTMLElement {
  constructor() {
    super();
    this.start = parseInt(this.getAttribute('data-start')) || 0;
    this.end = parseInt(this.getAttribute('data-end')) || 100;
    this.step = parseInt(this.getAttribute('data-step')) || 5;
    this.speed = parseInt(this.getAttribute('data-speed')) || 100;
    this.deferred = this.hasAttribute('data-deferred');
    this.currentValue = this.start;

    this.querySelector('.is-counter__num').textContent = this.currentValue;
  }

  connectedCallback() {
    if (this.deferred) {
      const parent = this.closest('.scroll-trigger');

      const isPartiallyVisible = (element) => {
        const rect = element.getBoundingClientRect();
        return rect.top < window.innerHeight && rect.bottom > 0;
      };

      const startCounting = () => {
        if (this.currentValue + this.step < this.end) {
          this.currentValue += this.step;
          this.querySelector('.is-counter__num').textContent = this.currentValue;
          setTimeout(startCounting, this.speed);
        } else {
          this.querySelector('.is-counter__num').textContent = this.end;
        }
      };

      if (parent) {
        if (isPartiallyVisible(parent)) {
          startCounting();
        } else {
          parent.addEventListener('on:sectionAnimation', startCounting);
        }
      } else {
        startCounting();
      }
    } else {
      this.startCounting();
    }
  }

  startCounting() {
    const increment = () => {
      if (this.currentValue + this.step < this.end) {
        this.currentValue += this.step;
        this.querySelector('.is-counter__num').textContent = this.currentValue;
        setTimeout(increment, this.speed);
      } else {
        this.querySelector('.is-counter__num').textContent = this.end;
      }
    };

    increment();
  }
}

customElements.define('is-counter', IsCounter);
